<!-- components/LanguageSwitcher.vue -->
<template>
  <div>
    <div v-if="isLoading" class="loading-spinner"><Spinner /></div>
    <div v-else>
      <button
        v-if="selectedLanguage === 'en'"
        @click="changeLanguage('ar')"
        aria-label="Switch to Arabic"
        class="bg-transparent text-primary fw-700"
      >
        العربية
        <img src="/assets/images/SA.svg" loading="lazy" alt="Saudi Flag" />
      </button>
      <button
        v-if="selectedLanguage === 'ar'"
        @click="changeLanguage('en')"
        aria-label="Switch to English"
        class="bg-transparent text-primary fw-700"
      >
        English
        <img
          src="/assets/images/uk-flag.svg"
          loading="lazy" alt="US Flag"
          width="22px"
          height="12px"
        />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useLanguageStore } from "@/store/language";
import Spinner from "./Spinner.vue";

// Call useI18n at the top of the setup function
const { locale, setLocaleMessage } = useI18n();

// Access the language store
const languageStore = useLanguageStore();

// Get reactive values from the store
const selectedLanguage = computed(() => languageStore.selectedLanguage);
const isLoading = computed(() => languageStore.isLoading);

// Update body direction and text alignment based on language
const setBodyDirection = (lang: string) => {
  const direction = lang === "ar" ? "rtl" : "ltr";
  document.body.style.direction = direction;
  document.body.style.textAlign = lang === "ar" ? "right" : "left";
};

// Change language and update store, i18n, and UI
const changeLanguage = async (lang: string) => {
  const translations = await languageStore.changeLanguage(lang);
  if (translations) {
    setLocaleMessage(lang, translations);
    locale.value = lang;
    setBodyDirection(lang);
  } else {
    console.error("Failed to fetch translations for language:", lang);
  }
};

// Watch for changes in the selected language
watch(selectedLanguage, (newLang) => {
  changeLanguage(newLang);
});

// Run once on component mount
onMounted(() => {
  setBodyDirection(selectedLanguage.value);
  changeLanguage(selectedLanguage.value);
});
</script>

<style scoped>
button {
  border: none;
}

.loading-spinner {
  width: 10px !important;
  height: 10px !important;
}
</style>
